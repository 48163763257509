<template>
    <div class="chat_page_wrapper">
        <div class="chat_messenger">
            <div class="chat_layer">
                <ChatBodyHeader :replySearch="replySearch" />
                <div class="chat_content">
                    <div 
                        ref="chatBodyArea"
                        class="chat_body" 
                        :class="currentPin && currentPin.results.length && 'open_pin'"
                        @scroll="scrollAction">
                        <template v-if="activeChat && activeChat.is_active">
                            <div v-if="reloadLoading" class="flex justify-center">
                                <a-spin />
                            </div>
                            <infinite-loading
                                v-if="!reloadLoading"
                                v-bind:distance="5"
                                :identifier="`${ activeChat.chat_uid }_top`"
                                direction="top"
                                @infinite="upScrollHandler">
                                <div slot="spinner">
                                    <div class="tp_spinner">
                                        <a-spin v-show="loading" />
                                    </div>
                                </div>
                                <div slot="no-more"></div>
                                <div slot="no-results"></div>
                            </infinite-loading>

                            <div
                                v-if="messages"
                                class="chat__log"
                                ref="chatLog">
                                <div 
                                    v-for="item in messages.value" 
                                    :key="item.id">
                                    <ChatMessage
                                        :replySearch="replySearch"
                                        :user="user"
                                        :resizeEvent="resizeEventMessage"
                                        :isScrolling="isScrolling"
                                        :messageItem="item" />
                                </div>
                            </div>

                            <infinite-loading
                                v-if="messages && messages.prev && messages.prev.length"
                                v-bind:distance="10"
                                :ref="`${ activeChat.chat_uid }_bottom`"
                                :identifier="`${ activeChat.chat_uid }_${ messages.prev }`"
                                @infinite="downScrollHandler">
                                <div slot="spinner">
                                    <div class="bt_spinner">
                                        <a-spin />
                                    </div>
                                </div>
                                <div slot="no-more"></div>
                                <div slot="no-results"></div>
                            </infinite-loading>

                            <transition name="slide">
                                <div
                                    v-if="replyMessage"
                                    class="reply_dummy"></div>
                            </transition>
                        </template>
                    </div>
                    <div class="chat_footer">
                        <ChatFooter
                            v-if="activeChat && activeChat.is_active"
                            :resizeEvent="resizeEvent"
                            ref="ChatFooter" />   
                    </div>
                </div>
                <div class="footer_dummy"></div>
            </div>
        </div>
    </div>
</template>

<script>
import ChatBodyHeader from '../ChatBodyHeader'
import ChatFooter from '../ChatFooter'
import ChatEventBus from '../../utils/ChatEventBus'
import ChatMessage from '../ChatMessage/index.vue'
import InfiniteLoading from 'vue-infinite-loading'
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'
import 'lazysizes'
import { useScroll } from '@vueuse/core'
let wFocus,
    scrollEndTid;
export default {
    components: {
        ChatBodyHeader,
        ChatFooter,
        ChatMessage,
        InfiniteLoading
    },
    props: {
        task: {
            type: Boolean,
            default: false
        },
        meetings: {
            type: Boolean,
            default: false
        },
        dealerChat: {
            type: Boolean,
            default: false
        }
    },
    metaInfo() {
        return {
            htmlAttrs: {
                class: 'chat_page bg_white'
            }
        }
    },
    computed: {
        ...mapState({
            activeChat: state=> state.chat.activeChat,
            user: state => state.user.user,
            pinMessage: state => state.chat.pinMessage,
            dialogLoading: state => state.chat.dialogLoading,
            messageListPrev: state => state.chat.messageListPrev,
            isMobile: state => state.isMobile,
            sidebarInfo: state => state.chat.sidebarInfo
        }),
        ...mapGetters({
            chatMessages: 'chat/chatMessages',
            getReplyMessage: 'chat/replyMessage',
            getCountMessages: 'chat/getCountMessages'
        }),
        messages() {
            return this.chatMessages(this.activeChat.chat_uid)
        },
        replyMessage() {
            return this.getReplyMessage(this.activeChat.chat_uid)
        },
        currentMessage() {
            if(this.activeChat && this.messages){
                return this.messages
            }
            else
                return false
        },
        currentPin() {
            if(this.activeChat && this.pinMessage[this.activeChat.chat_uid])
                return this.pinMessage[this.activeChat.chat_uid]
            else
                return false
        },
        missedCount() {
            return this.getCountMessages(this.activeChat.chat_uid)
        }
    },
    data() {
        return {
            loading: false,
            emptyMessage: false,
            showDownBtn: false,
            loadingDown: false,
            init: true,
            isScrolling: false,
            reloadLoading: false,
            initChat: true,
            handleState: null
        }
    },
    methods: {
        ...mapActions({
            getCurrentChat: 'chat/getCurrentChat',
            getPrivateChat: 'chat/getPrivateChat',
            getMessageScroll: 'chat/getMessageScroll',
            getMessage: 'chat/getMessage',
            getMessageDownScroll: 'chat/getMessageDownScroll',
            getPinMessage: 'chat/getPinMessage'
        }),
        ...mapMutations({
            clearMessage: "chat/clearMessage",
            setScrollToped: "chat/setScrollToped",
            clearMessageCount: "chat/clearMessageCount",
            clearMessageCountMobile: 'chat/clearMessageCountMobile',
            CLEAR_CHAT: 'chat/CLEAR_CHAT',
            TOGGLE_INFO_SIDEBAR: 'chat/TOGGLE_INFO_SIDEBAR'
        }),
        infiniteTopHandler($state) {
            this.handleState = $state
            this.onScroll($state)
        },
        onScroll($state) {
            clearTimeout(scrollEndTid)
            scrollEndTid = setTimeout(() => {
                if (this.handleState) {
                    this.upScrollHandler($state)
                    this.handleState = null
                }
            }, 200)
        },
        resizeEventMessage() {
            this.resizeEvent(650)
        },
        resizeEvent(addH = 250) {
            this.$nextTick(() => {
                if(this.$refs['chatBodyArea']) {
                    const currScroll = this.$refs['chatBodyArea'].scrollHeight - this.$refs['chatBodyArea'].scrollTop,
                        checkScroll = this.$refs['chatBodyArea'].clientHeight + addH

                    if(currScroll < checkScroll) {
                        this.scrollDown()
                    }
                }
            })
        },
        async getOpenChat(reload = false) {
            try {
                if (reload) {
                    this.$nextTick(() => {
                        this.scrollDown();
                    });
                }
                const chat_id = this.$route.params.id;
                await this.getCurrentChat(chat_id);
                await this.getPinMessages();
                if (!this.activeChat.is_public)
                    this.$socket.client.emit('chat_status_user', { 
                        chat_uid: this.activeChat.chat_uid, 
                        user_uid: this.activeChat.recipient.id 
                    });

                if (this.isMobile && this.messages?.value?.length) {
                    this.$nextTick(() => {
                        if (!reload) {
                            setTimeout(() => {
                                this.scrollDown();
                            }, 40);
                        }
                    });
                }
            } catch (error) {
                if (!this.dealerChat)
                    this.$router.push({ name: 'chat' });

                if (error.detail) {
                    if (error.detail === 'Страница не найдена.') {
                        this.$message.error(this.$t('chat.chat_not_found')); // Chat not found
                    } else {
                        this.$message.error(error.detail);
                    }
                }
            }
        },
        async getPinMessages() {
            try {
                await this.$store.dispatch('chat/getPinMessage', {
                    page_size: 10
                })
            } catch(e) {

            }
        },
        async downAction(){
            this.loadingDown = true
            await this.scrollTestPrev()
            this.scrollDown()
            this.clearCount(this.activeChat.chat_uid)
            this.loadingDown = false
        },
        async clearCount(){
            if(this.missedCount > 0) { 
                this.clearMessageCount(this.activeChat.chat_uid)
            }
        },
        scrollAction(){
            this.$nextTick(() => {
                let container = this.$refs['chatBodyArea']
                if(container !== undefined){ 
                    const currScroll = container.scrollHeight - container.scrollTop,
                        checkScroll = container.clientHeight + 250

                    if(currScroll >= checkScroll) {
                        this.showDownBtn = true
                        this.setScrollToped(true)
                    } else {
                        this.showDownBtn = false
                        this.setScrollToped(false)
                        this.clearCount()
                    }
                }
            })
        },
        async replySearch(message) {
            const messageScroll = (elem) => {
                let top = elem.offsetTop - 65

                if(this.currentPin && this.currentPin.results.length)
                    top = top - 43

                //document.querySelector('.chat-content-scroll-area').scrollTo({top, behavior: 'smooth'})
                elem.scrollIntoView({block: "center", behavior: "smooth"})

                if (!elem.classList.contains('flashing')) {
                    let observer = new IntersectionObserver(() => {
                        observer.unobserve(elem)
                        elem.classList.add('flashing')
                        setTimeout(() => {
                            elem.classList.remove('flashing')
                        }, 1200)
                    }, this.observerOptions)
                    observer.observe(elem)
                }
            }
            // try {

            const searchMessage = this.currentMessage.value.find(item =>{ 
                // console.log("item", item)
                // console.log("message", message)
                return  item.message_uid === message.message_uid
            }
            )

            if(searchMessage) {
                const elem = document.getElementById(`message_${ searchMessage.message_uid }`)
                messageScroll(elem)
            } else {
                await this.$store.dispatch('chat/searchMessages', message)
                const elem = document.getElementById(`message_${ message.message_uid }`)
                messageScroll(elem)
            }
            // } catch(e) {
            //     console.log(e)
            // } finally {
            ChatEventBus.$emit('CLOSE_PIN_DRAWER')
            // }
        },
        scrollDown() {
            this.$nextTick(() => {
                if(this.$refs['chatBodyArea'])
                    this.$refs['chatBodyArea'].scrollTop = this.$refs['chatBodyArea'].scrollHeight          
            })
        },
        async scrollTestPrev() {
            if(this.messageListPrev)
                await this.getMessage({refresh: true})
        },
        async downScrollHandler() {
            if(this.messages && !this.loading) {
                if(this.messages.prev && this.messages.prev.length) {
                    try {
                        this.loading = true
                        await this.getMessageDownScroll()
                        if(this.messages.bottomStatus)
                            $state.loaded()
                        else
                            $state.complete()
                    } catch(e) {
                    } finally {
                        this.loading = false
                    }
                } else {
                    $state.complete()
                }
            }

            if(this.init && this.isMobile) {
                this.init = false
                setTimeout(() => {
                    this.scrollDown()
                }, 150)
            }
        },
        async upScrollHandler($state) {
            if(this.messages && !this.loading && !this.reloadLoading) {
                // Запрашиваем сообщение при скролле
                if(this.messages.next && this.messages.next.length) {
                    if(this.initChat && this.messages?.value?.length) {
                        this.$nextTick(() => {
                            this.scrollDown()
                        })
                    }
                    this.initChat = false
                    try {
                        this.loading = true
                        await this.getMessageScroll()
                        if(this.messages.status)
                            $state.loaded()
                        else
                            $state.complete()
                    } catch(e) {
                    } finally {
                        this.loading = false
                    }
                } else if(this.messages.next === undefined) {
                    this.initChat = false
                    this.clearMessage()
                    await this.getMessage()
                    await this.getPinMessage({ page_size: 10, page: 1 })
                   
                    if(this.messages && this.messages.status)
                        $state.loaded()
                    else
                        $state.complete()
                } else {
                    if(this.initChat) {
                        this.initChat = false
                        this.$nextTick(() => {
                            this.scrollDown()
                        })
                    }
                }
            } else {
                // Запрашиваем сообщения изначально
                try {
                    this.loading = true
                 
                    await this.getMessage()
                    this.scrollDown()
                    this.initChat = false
                    await this.getPinMessage({ page_size: 10, page: 1 })
                   
                    this.scrollDown()
                    if(this.messages && this.messages.status)
                        $state.loaded()
                    else
                        $state.complete()
                } catch(e) {
                    console.log(e)
                } finally {
                    this.scrollDown()

                    if(!this.messages)
                        this.emptyMessage = true
                        
                    this.loading = false

                    this.clearMessageCountMobile(this.activeChat.chat_uid)
                }
            }
        },
        async reloadMessage() {
            try {
                this.reloadLoading = true
                await this.clearMessage()
                await this.getMessage({refresh: true})
                await this.getOpenChat(true)
            } catch(e) {
                console.log(e)
            } finally {
                this.reloadLoading = false
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            const { isScrolling } = useScroll(this.$refs['chatBodyArea'])
            this.isScrolling = isScrolling
        })

        if(!this.activeChat?.no_create) {
            this.getOpenChat()
        }

        ChatEventBus.$on('arreaScroll', () => {
            this.$nextTick(() => {
                let container = this.$refs['chatBodyArea']
                if(container){ 
                    const currScroll = container.scrollHeight - container.scrollTop,
                        checkScroll = container.clientHeight + 250

                    if(currScroll <= checkScroll) {
                        this.scrollDown()
                        setTimeout(() => {
                            if(currScroll <= checkScroll) {
                                this.scrollDown()
                            }
                        }, 1000)
                    }
                }
            })
        })
        ChatEventBus.$on('arreaScrollDown', () => {
            this.scrollDown()
            this.clearCount()
        })

        if(this.isMobile) {
            wFocus = window.onfocus = () => {
                this.reloadMessage()
            }
        }
    },
    beforeCreate() {
        this.$store.commit('SHOW_FOOTER', false)
        this.$store.commit('SHOW_HEADER', false)
    },
    beforeDestroy() {
        ChatEventBus.$off('arreaScroll')
        ChatEventBus.$off('arreaScrollDown')
        this.CLEAR_CHAT()
        this.$store.commit('SHOW_FOOTER', true)
        this.$store.commit('SHOW_HEADER', true)
        wFocus = null
        this.initChat = true
        if(this.sidebarInfo) {
            this.TOGGLE_INFO_SIDEBAR(false)
        }
    }
}
</script>

<style lang="scss" scoped>
.chat_page_wrapper{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    .chat_body{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 1px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        transform: translateZ(0);
        position: relative;
        &::v-deep{
            .tp_spinner,
            .bt_spinner{
                .ant-spin{
                    background: #ffffff;
                    border-radius: 50%;
                    height: 35px;
                    width: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: 0 2px 8px rgba(0,0,0,.15);
                }
            }
            .bt_spinner{
                width: 100%;
                z-index: 5;
                display: flex;
                justify-content: center;
            }
            .tp_spinner{
                position: fixed;
                top: 5px;
                left: 0;
                width: 100%;
                z-index: 5;
                display: flex;
                justify-content: center;
            }
        }
    }
    .chat_footer{
        flex-shrink: 0;
        transform: translateZ(0);
        background: #ffffff;
    }
    .chat_messenger{
        height: 100%;
        position: relative;
        background-color: #f2f2f2;
        background-image: url('~@apps/vue2ChatComponent/assets/chat_bg.png');
        .chat_layer{
            height: 100%;
            box-sizing: border-box;
            padding-bottom: 0;
            --safe-area-inset-bottom: env(safe-area-inset-bottom);
            padding-bottom: calc(var(--safe-area-inset-bottom));
            display: flex;
            flex-direction: column;
        }
    }
    .chat_content{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 1px;
        overflow: hidden;
        .chat_body{
            padding: 5px 15px;
        }
    }
    .footer_dummy{
        position: relative;
    }
}
</style>